import React from 'react'
import { graphql } from 'gatsby'
import img from '../../static/images/bg_1.jpg';
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Image from '../components/Image';
import Accordion from '../components/Accordion';
import nadar from '../../static/images/nadar pic.jpg';
import lewin from '../../static/images/lewin.png';
import './AboutPage.css';

// Export Template for use in CMS preview
export const AboutPageTemplate = ({ title, subtitle, featuredImage, meetNadar, meetNadar2, meetNadar3, meetLewin, meetLewin2, care, body }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          {/* <Content source={body} /> */}
          <div className="Contact--Details">
            
         
          <Image alt="Capital Area Research Team" src={nadar} resolutions="400x" className="care-group"/>
          </div>
        </div>

        <div>
          {/* <ContactForm name="Enquiry Form" /> */}
          <h2>Meet Dr. Nadar</h2>
          <Content source={meetNadar} />
          <Content source={meetNadar2} />
          <Content source={meetNadar3} />
          <h4>Medical Training</h4>
<p>Education: Medical School, Madras Medical College, 1985</p>

<p>Residency: Internal Medicine Marshall University Affiliated Hospitals, 1990</p>

<p>Fellowship: Cardiovascular Dis. Marshall University Affiliated Hospitals, 1993</p>
        </div>
      </div>
    </section>

    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          {/* <Content source={body} /> */}
          <div className="Contact--Details">
            
         
          <Image alt="Capital Area Research Team" src={lewin} resolutions="small" className="care-group"/>
          </div>
        </div>

        <div>
          {/* <ContactForm name="Enquiry Form" /> */}
          <h2>Meet Dr. Lewin</h2>
          <Content source={meetLewin} />
          <Content source={meetLewin2} />
 
          <h4>Medical Training</h4>
<p>Education: University of Buffalo School of Medicine and Biomedical Sciences</p>

<p>Residency: State University of New York at Buffalo, 1976</p>

<p>Fellowship: University of Michigan Cardiology, 1978</p>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
     {/* <h2>Why Choose Capital Area Cardiovascular Associates?</h2> */}
      <Content source={body} />
      {/* <h2>Meet Dr. Nadar</h2>
      <Image alt="Capital Area Research Team" src={nadar} resolutions="400x" className="care-group"/>
      <Content source={meetNadar} />
        
        <h2>Meet Dr. Lewin</h2>
        <Image alt="Capital Area Research Team" src={lewin} resolutions="small" className="care-group"/>
        <Content source={meetLewin} />
        <h2>Capital Area Research</h2>
        <Content source={care} /> */}
        {/* <Content source={employeeList} />
        <div className="container">
          <Accordion items={accordion} />
        </div>
        <Image alt="Capital Area Research Team, Newport" src={amy} resolutions="small" className="care-group"/>
        <Content source={employeeList2} /> */}
        {/* <div className="container">
            <Accordion items={accordion2} />
        </div> */}
      </div>
    </section>


  </main>
)

// Export Default HomePage for front-end
const AboutPage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <AboutPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default AboutPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        meetNadar
        meetNadar2
        meetNadar3
        meetLewin 
        meetLewin2
        care
        meta {
          description
          title
          image
        }
      }
    }
  }
`
